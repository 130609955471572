import React, { useEffect, useState } from "react";
import "./Duval.css";
import Spinner from "../../components/spinner/Spinner";

const Duval = () => {
  let baseURL = process.env.REACT_APP_BASE_URL;
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [duvalList, setDuvalList] = useState([]);

  useEffect(() => {
    loadDuvalList();
  }, []);

  const loadDuvalList = async () => {
    try {
      fetch(baseURL + "api/get-duvallist")
        .then((response) => response.json())
        .then((data) => {
          if (data) {
            setDuvalList(data);
            setLoading(false);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const doLoadCasesManually = async (btn) => {
    try {
      btn.setAttribute("disabled", "disabled");
      btn.style.background = "#555";

      fetch(baseURL + "api/get-foreclosure-cases")
        .then((data) => {})
        .catch((error) => {
          console.error("Error:", error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  return (
    <div>
      <div className="top_buttons">
        <button
          className="big_button mr20"
          id="showGroupPopup"
          type="button"
          onClick={(e) => {
            doLoadCasesManually(e.target);
          }}
        >
          Force Load
        </button>
      </div>

      <table id="dataTable" className="agent_table aip_table">
        <thead>
          <tr>
            <th style={{ width: "200px" }}>CaseID</th>
            <th style={{ width: "200px" }}>CaseDate</th>
            <th>CaseAddress</th>
            <th>Street</th>
            <th>City</th>
            <th style={{ width: "200px" }}>State</th>
            <th style={{ width: "200px" }}>Zip</th>
            <th>Defendants</th>
          </tr>
        </thead>
        <tbody>
          {duvalList &&
            duvalList.map((oneCase, index) => (
              <tr key={index}>
                <td>{oneCase.caseNumber}</td>
                <td>{oneCase.caseFileDate}</td>
                <td>
                  {oneCase.address}
                  <br />
                  <br />
                  {oneCase.googleAddress}
                </td>
                <td>{oneCase.street}</td>
                <td>{oneCase.city}</td>
                <td>{oneCase.state}</td>
                <td>{oneCase.zipCode}</td>
                <td>{oneCase.defendants && oneCase.defendants.length > 0 ? oneCase.defendants.map((oneDef) => <div>{oneDef?.name}</div>) : ""}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default Duval;
